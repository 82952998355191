







import {Component, Ref, Vue} from 'vue-property-decorator';
import ContextUsuario from '@/components/ContextOptions/ContextUsuario.vue';
import VueContext from 'vue-context';

@Component({
  components: {
    ContextUsuario,
    UsuarioContext,
  },
})
export default class UsuarioContext extends Vue {
  @Ref() readonly menu!: VueContext;

  public open(event: MouseEvent, item: IUsuarios): void {
    this.menu.open(event, item);
  }

  public close(): void {
    this.menu.close();
    this.$emit('close');
  }
}
