













































































import {Component, Ref, Vue} from 'vue-property-decorator';
import {BTable, BvTableCtxObject} from 'bootstrap-vue';
import {eventOff, eventOn} from 'vue-context/src/js/utils';
import {Usuario} from '@/plugins/api';
import {columns, columnsFilter} from './components/tabela';
import UsuariosContext from './components/ContextMenu.vue';
import IndicadorContext from "@/views/app/Indicadores/components/ContextMenu.vue";

@Component({
  components: {
    IndicadorContext,
    UsuariosContext: UsuariosContext,
  },
})
export default class UsuariosIndex extends Vue {
  @Ref() readonly table!: BTable;
  @Ref() readonly context!: UsuariosContext;

  public loading = false;

  public currentPage = 1;
  public totalRows = 1;
  public perPage = 50;
  public columns = columns;

  created(): void {
    this.$store.commit('SET_TABELA', 'Usuários');
    this.$store.commit('SET_COLUNAS', columnsFilter);
  }

  mounted(): void {
    eventOn(this.table.$el, 'scroll', this.context.menu.close);
    this.$root.$on('filtros', this.refresh);
  }

  destroy(): void {
    eventOff(this.table.$el, 'scroll', this.context.menu.close);
    this.$root.$off('filtros', this.refresh);
  }

  get filtros(): IFiltroValues[] {
    return this.$store.getters.filtrosSelecionados;
  }

  provider(
      context: BvTableCtxObject,
      callback: (data: IUsuarios[]) => void
  ): void {
    this.loading = true;
    const request = {
      params: {
        page: context.currentPage,
        size: context.perPage,
        sortBy: context.sortBy,
        sortDesc: context.sortDesc ? 'DESC' : 'ASC',
        filters: this.filtros,
      },
    };

    Usuario.get(request)
        .then((response: GetUsuariosResponse) => {

          const usuarios = response.data;
          callback(usuarios);
          this.totalRows = response.metadata.total;
        })
        .catch(() => {

          callback([]);
        })
        .finally(() => {

          this.loading = false;
        });
  }

  public refresh(): void {

    this.table.refresh();
  }

  public contextMenu(item: IUsuarios, data: number, event: MouseEvent): void {

    event.preventDefault();
    this.table.selectRow(data);
    this.context.open(event, item);
  }
}
