import i18n from '@/i18n';
import {BvTableField} from 'bootstrap-vue';

const columns: Array<{ key: string } & BvTableField> = [
    {
        label: i18n.tc('colunas.nome.usuario', 1) as string,
        key: 'nome',
        sortable: true
    },
    {
        label: i18n.tc('colunas.contato.email', 1) as string,
        key: 'email',
        sortable: true
    },
    {
        label: i18n.tc('colunas.data.cadastro', 2) as string,
        key: 'time',
        thStyle: 'width: 100px',
        sortable: true,
    },
];

const columnsFilter: IFiltroField[] = [
    {label: i18n.t('colunas.codigo.usuario') as string, id: 'usuarios_financeiro.id', type: 'id'},
    {label: i18n.tc('colunas.nome.usuario', 2) as string, id: 'usuarios_financeiro.nome', type: 'text'},
    {label: i18n.tc('colunas.contato.email', 2) as string, id: 'usuarios_financeiro.email', type: 'text'},
];

export {columns, columnsFilter};
