<template>
  <b-card>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <div class="row">
        <div class="col-md-6">
          <b-form-group id="input-group-2" label="Nome:" label-for="nome">
            <b-form-input
                id="nome"
                v-model="form.nome"
                placeholder="Insira o nome"
                required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group id="input-group-2" label="Sobrenome:" label-for="sobrenome">
            <b-form-input
                id="sobrenome"
                v-model="form.sobrenome"
                placeholder="Insira o sobrenome"
                required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-form-group
          id="input-group-1"
          label="E-mail:"
          label-for="email"
      >
        <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            placeholder="Insira seu e-mail"
            required
        ></b-form-input>
      </b-form-group>

      <div class="row">
        <div class="col-md-6">
          <b-form-group
              id="input-group-1"
              label="Senha:"
              label-for="senha"
          >
            <b-form-input
                id="senha"
                v-model="form.senha"
                type="password"
                placeholder="Insira uma senha"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group
              id="input-group-1"
              label="Confirmar Senha:"
              label-for="senha-confirma"
          >
            <b-form-input
                id="senha-confirma"
                v-model="form.confirma_senha"
                type="password"
                placeholder="Confirmar senha"
                @input="validateForm"
            ></b-form-input>
          </b-form-group>
          <p v-if="errorMessage" style="color:red">{{ errorMessage }}</p>
        </div>
      </div>

      <b-form-group class="text-right">
        <b-button class="mr-2" type="reset" variant="secondary">Cancelar</b-button>
        <b-button type="submit" variant="success">Cadastrar</b-button>
      </b-form-group>
    </b-form>
  </b-card>
</template>

<script>
import {Usuario} from "@/plugins/api";

export default {
  data() {
    return {
      userId: this.$route.params.id,
      form: {
        nome: '',
        sobrenome: '',
        email: '',
        senha: null,
        confirma_senha: null,
      },
      errorMessage: null,
      show: true,
      formdata: null
    }
  },
  methods: {
    validateForm() {
      if (this.form.senha !== this.form.confirma_senha) {
        this.errorMessage = 'Senhas não combinam';
        return false;
      }
      this.errorMessage = null;
      return true;
    },

    getFormValues() {
      Usuario.getByID(this.userId).then((response) => {
        const data = response.data;
        this.form.nome = data.nome;
        this.form.sobrenome = data.sobrenome;
        this.form.email = data.email;
      })
    },

    onSubmit(event) {
      event.preventDefault()
      this.form.id = this.userId;
      this.formdata = JSON.stringify(this.form);
      const request = {
        params: this.formdata
      }


      Usuario.store(request)
          .then((response) => {

            if (response.success) {
              this.$swal({
                icon: 'success',
                title: response.message,
              });
              this.$router.push('/usuarios');
            } else {

              this.$swal({
                icon: 'warning',
                title: response.message,
              });
            }
          })
          .catch((error) => {

            this.$swal({
              icon: 'error',
              title: error,
            });
          })
          .finally(() => {

            this.loading = false;
          });

    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.getFormValues();
      this.form.senha = null
      this.form.confirma_senha = null
    }
  },
  mounted() {
    this.getFormValues();
  }
}
</script>