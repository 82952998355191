



















import {Component, Prop, Vue} from 'vue-property-decorator';
import {Usuario} from "@/plugins/api";

@Component
export default class ContextUsuario extends Vue {
  @Prop() public readonly usuario!: string;

  public editar() {
    this.$router.push('/usuarios/editar/' + this.usuario);
  }

  public excluir() {

    const idUsuario = parseInt(this.usuario);

    Usuario.delete(idUsuario)
        .then((response) => {
          if (response.success) {
            this.$swal({
              icon: 'success',
              title: response.message,
            });
          } else {

            this.$swal({
              icon: 'warning',
              title: response.message,
            });
          }
        })
        .catch((error) => {

          this.$swal({
            icon: 'error',
            title: error,
          });
        });
  }
}
